<script>
/**
 * Tray Settings Page
 * @version 1.0.0
 * @since 3.16.0
 */
import Page from '@/views/admin/SinglePage.vue';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { INTEGRATION_STATUSES, INTEGRATIONS_TYPES, LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { openConfigWindow } from '@/util/configWindow';
import { venueGetConfigWizardURL } from '@/api/endpoints/venue-tray';
import bus from '@/util/trayBus';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzButton,
    Page,
    VStatusBadge,
    EzConfirmationModal,
    EzButtonDropdown,
    EzLoader,
  },
  data() {
    return {
      INTEGRATION_STATUSES,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'integrationVenueDraft',
    ]),
    ...mapGetters('entities/venues', [
      'isSelectedIntegrationConnected',
      'isSelectedIntegrationNeedToUpdate',
      'isSelectedIntegrationNotEnabled',
      'isSelectedIntegrationNotConnected',
    ]),
    venueId() {
      return this.$route.params.id;
    },
    canSeeTabs() {
      return this.isSelectedIntegrationConnected || this.isSelectedIntegrationNeedToUpdate;
    },
    allSet() {
      return this.integrationVenueDraft?.status === INTEGRATION_STATUSES.connected;
    },
    isTrayDefault() {
      return this.integrationVenueDraft?.type === INTEGRATIONS_TYPES.default;
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.VENUE_DISCONNECT_INTEGRATIONS,
      ]);
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueDisconnectIntegrations',
      'venueEnableConnectedIntegrations',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_SELECTED_INTEGRATION',
      'CLEAR_SELECTED_INTEGRATION',
    ]),
    async update() {
      const { data } = await venueGetConfigWizardURL(
        this.venueId,
        this.integrationVenueDraft.instanceId,
      );
      const configWindow = openConfigWindow();
      configWindow.location = data.data;
    },
    async confirmDisconnect() {
      await this.venueDisconnectIntegrations({
        venueId: this.venueId,
        solutionId: this.integrationVenueDraft.instanceId,
      });
      this.CLEAR_SELECTED_INTEGRATION();
      this.$refs.disconnectModal.close();
      await this.$router.push({
        name: 'venue-settings-integrations',
        params: {
          id: this.venueId,
          flash: {
            type: 'success',
            title: `Integration ${this.integrationVenueDraft.title} successfully disconnected!`,
          },
        },
      });
    },
    onUpdateFinished() {
      if (this.isSelectedIntegrationNeedToUpdate || this.isSelectedIntegrationConnected) {
        this.enableIntegration();
      }
    },
    onUpdateNotFinished() {
      if (this.isSelectedIntegrationNeedToUpdate || this.isSelectedIntegrationConnected) {
        this.$router.push({ name: 'venue-settings-integrations', params: { id: this.venueId } });
      }
    },
    async enableIntegration() {
      const { data: { data } } = await this.venueEnableConnectedIntegrations({
        venueId: this.venueId,
        solutionId: this.integrationVenueDraft.instanceId,
      });
      this.UPDATE_SELECTED_INTEGRATION(data);
      await flash.success({
        title: this.$t('integrations.tray.flashSuccessTitle'),
        message: this.$t('integrations.tray.flashSuccessMessage'),
        delay: 6000,
      });
    },
  },
  mounted() {
    bus.$on('finished', this.onUpdateFinished);
    bus.$on('notFinished', this.onUpdateNotFinished);
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link
        :to="{ name: 'venue-settings-integrations', params: { id: venueId } }"
      >
        <font-awesome-icon icon="arrow-left"/>
        Back to {{ $t('global.distributor') }} Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img :src="integrationVenueDraft.icon" width="32" height="32" alt=""/>
        <h2 class="m-0 ml-8 xero-title">{{ integrationVenueDraft.title }}</h2>
        <template>
          <v-status-badge type="orange" v-if="isSelectedIntegrationNotEnabled">
            {{ $t('integrations.actions.actionNeeded') }}
          </v-status-badge>
          <v-status-badge type="green" v-if="isSelectedIntegrationConnected && allSet">
            {{ $t('integrations.actions.allSet') }}
          </v-status-badge>
          <v-status-badge type="blue" v-if="isSelectedIntegrationNeedToUpdate">
            {{ $t('integrations.actions.actionUpdate') }}
          </v-status-badge>
        </template>
      </div>
    </template>
    <template #actions>
      <div>
        <ez-button-dropdown v-if="!isSelectedIntegrationNotConnected" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h"/>
          </template>
          <template #dropdown>
            <ez-button type="link" @click="update">
              Update integration
            </ez-button>
            <ez-button v-open="'disconnectModal'" type="red-link">
              {{ $t('global.actions.disconnect') }}
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </template>
    <template #navigation v-if="!isTrayDefault">
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="'span'"
            :to="{ name: '' }"
          >
            Info
          </component>
        </li>
      </ul>
    </template>


    <ez-confirmation-modal ref="disconnectModal" type="red">
      <template #title>Disconnect from {{ integrationVenueDraft.title }}?</template>
      <template #content>
        <div class="xero-description">
          You’re about to disconnect your {{ oezPlatformName }} account from {{ integrationVenueDraft.title }}.
        </div>
      </template>
      <template #footer>
        <ez-button v-close="'disconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </page>
</template>
<style lang="scss" scoped>
.xero-title {
  @include font-size(24px);
  font-weight: 500;
}
.title {
  img {
    border: 1px solid #E1E5ED;
    border-radius: 50%;
  }
}
</style>
