<script>
/**
 * Tray Connect or Enable Screen
 * @version 1.0.0
 * @since 3.16.0
 */

import EmptyState from '@/views/common/empty-state';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzInput from '@/components/ui/Input/EzInput';
import EzForm from '@/components/ui/Form/EzForm';
import { openConfigWindow } from '@/util/configWindow';
import bus from '@/util/trayBus';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';
import { venueGetConfigWizardURL } from '@/api/endpoints/venue-tray';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EmptyState,
    EzButton,
    EzConfirmationModal,
    EzInput,
    EzForm,
    EzLoader,
  },
  data() {
    return {
      isNameValid: false,
      formKey: 'createSolutionVenue',
      loadingKeyForm: 'createSolutionLoadingVenue',
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'integrationVenueDraft',
    ]),
    ...mapState('entities/users', [
      'loggedUser',
    ]),
    ...mapGetters('entities/venues', [
      'isSelectedIntegrationNotEnabled',
      'isSelectedIntegrationConnected',
      'isSelectedIntegrationNotConnected',
      'isSelectedIntegrationActionNeeded',
    ]),
    venueId() {
      return this.$route.params.id;
    },
    solutionId() {
      return { solutionId: this.integrationVenueDraft.id };
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.VENUE_ENABLE_INTEGRATIONS,
      ]);
    },
    platformName() {
      return this.integrationVenueDraft?.providerName || 'Platform Name';
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueEnableConnectedIntegrations',
      'venueFetchConnectedIntegrations',
    ]),
    ...mapMutations('entities/venues', [
      'SET_SELECTED_INTEGRATION',
      'UPDATE_SELECTED_INTEGRATION',
    ]),
    confirmConnection() {
      this.$refs.form.onSubmit();
    },
    redirectToSettings() {
      if (this.isSelectedIntegrationConnected) {
        this.$router.replace({ name: 'venue-tray-connect-or-enable', params: { id: this.venueId } });
      }
    },
    onSubmitSuccess({ data }) {
      const configWindow = openConfigWindow();
      configWindow.location = data.data.configUrl;
      this.UPDATE_SELECTED_INTEGRATION(data.data);
    },
    onSubmitError(err) {
      // eslint-disable-next-line no-console
      console.error(err);
    },
    focusInput() {
      this.$refs.nameInput.focus();
    },
    async update() {
      const { data } = await venueGetConfigWizardURL(
        this.venueId,
        this.integrationVenueDraft.instanceId,
      );
      const configWindow = openConfigWindow();
      configWindow.location = data.data;
    },
    async onFinishedIntegration() {
      if (this.isSelectedIntegrationActionNeeded) {
        await this.enableIntegration();
        return;
      }
      this.$refs.connectModal.close();
      await this.enableIntegration();
    },
    isNotFinished() {
      this.$router.push({ name: 'venue-settings-integrations', params: { id: this.venueId } });
    },
    async enableIntegration() {
      const { data: { data } } = await this.venueEnableConnectedIntegrations({
        venueId: this.venueId,
        solutionId: this.integrationVenueDraft.instanceId,
      });
      this.SET_SELECTED_INTEGRATION(data);
      await flash.success({
        title: this.$t('integrations.tray.flashSuccessTitle'),
        message: this.$t('integrations.tray.flashSuccessMessage'),
        delay: 6000,
      });
    },
    onNameInputChange(val) {
      this.isNameValid = !!val.trim();
    },
  },
  mounted() {
    bus.$on('finished', this.onFinishedIntegration);
    bus.$on('notFinished', this.isNotFinished);
  },
  beforeDestroy() {
    bus.$off('finished');
    bus.$off('notFinished');
  },
};
</script>
<template>
  <div class="not-connected">
    <empty-state>
      <template #badge>
        <div class="empty-image-wrapper">
          <img class="empty-image" src="@/assets/no-standing-scheduled-state.svg" alt="" width="160" height="138">
          <img class="empty-image-logo" :src="integrationVenueDraft.icon" width="32" height="32" alt=""/>
        </div>
      </template>
      <template #title>
        <span v-if="isSelectedIntegrationConnected">Your {{ platformName }} integration is all set</span>
        <span v-if="isSelectedIntegrationNotEnabled">Enable your {{ platformName }} account</span>
        <span v-if="isSelectedIntegrationNotConnected">Connect your {{ platformName }} account</span>
      </template>
      <template #info>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text.
      </template>

      <div class="mt-16">
        <ez-button @click="update" class="action" key="enableEcommerce" v-if="isSelectedIntegrationNotEnabled">
          <span>{{ $t('global.actions.enable') }}</span>
          <font-awesome-icon icon="link"/>
        </ez-button>
        <ez-button v-if="isSelectedIntegrationNotConnected" class="action" key="connectEcommerce" v-open="'connectModal'">
          <span>{{ $t('global.actions.connect') }}</span>
          <font-awesome-icon icon="link"/>
        </ez-button>
      </div>
    </empty-state>

    <ez-confirmation-modal ref="connectModal" icon="question">
      <template #title>Name Your {{ platformName }} Account</template>
      <template #content>
        <div class="xero-description">
          Add integration name in order to enter the configuration wizard.
        </div>
        <hr>
        <ez-form
          :formKey="formKey"
          :loading-key="loadingKeyForm"
          :action="`/venues/${venueId}/tray/solution-instances`"
          method="post"
          :additional-data="solutionId"
          @success="onSubmitSuccess"
          @error="onSubmitError"
          ref="form"
        >
          <ez-input
            ref="nameInput"
            class="integration-name mt-12"
            label="Account Name"
            formKey="accountName"
            name="name"
            :placeholder="`${platformName} - ${loggedUser.group.name}`"
            @onChange="onNameInputChange"
          />
        </ez-form>
      </template>
      <template #footer>
        <ez-button type="link" v-close="'connectModal'">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button class="ml-12" @click="confirmConnection" :disabled="!isNameValid">
          {{ $t('global.actions.saveAndContinue') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
.not-connected { padding-top: 80px; }
:deep() .ez-empty-state {
  p {
    @include font-size(12px, 18px);
  }
  h3 {
    @include font-size(18px, 24px);
    font-weight: bold;
  }
}

.action {
  :deep() {
    svg {
      margin: 0 0 0 8px;
    }
  }
}

.empty-image-wrapper {
  position: relative;
  .empty-image {
    position: relative;
  }
  .empty-image-logo {
    position: absolute;
    right: 22px;
    bottom: 0;
    width: 65px;
    height: 65px;
  }
}
</style>
