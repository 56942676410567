import httpService from '@/api/http';
import { LOADING_KEY } from '@/util/constants';

/**
 * @see {@link https://documenter.getpostman.com/view/3171682/RzfZNY7x?version=latest#39d7a501-527c-48a4-9f07-434680556146}
 *
 * @param {String} venueId
 * @param {String} instanceId
 * @param {String} loadingKey
 * @return {Promise<AxiosResponse<any>>}
 */
export function venueGetConfigWizardURL(
  venueId,
  instanceId,
  loadingKey = LOADING_KEY.VENUE_GET_CONFIG_WIZARD_URL,
) {
  return httpService.get(`/venues/${venueId}/tray/solution-instances/${instanceId}/config-url`, {
    headers: {
      loadingKey,
    },
  });
}
